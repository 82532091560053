import Vue from 'vue';
import Component from 'vue-class-component';
import FormRules from '@/partials/FormRules';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class TimeSlot extends Vue {
    @Prop() dense!: boolean;
    @Prop() value!: any;
    @Prop({ type: Boolean, default: false }) keepOriginalDate!: boolean;

    public formRules = FormRules;
    public input: any = this.value;

    @Watch('input', { deep: true })
    private onInputChange(): void {
        this.$emit('input', this.input);
    }

    mounted(){
        const time_from_date = new Date(this.input.time_from);
        const today_from = new Date();
        today_from.setHours(time_from_date.getHours());
        today_from.setMinutes(time_from_date.getMinutes());

        const time_from_input = this.keepOriginalDate ? this.$dayjs(this.input[ 'time_from' ]).toDate() : today_from;
        Vue.set(this.input, 'time_from', time_from_input);

        const time_till_date = new Date(this.input.time_till);
        const today_till = new Date();
        today_till.setHours(time_till_date.getHours());
        today_till.setMinutes(time_till_date.getMinutes());

        const time_till_input = this.keepOriginalDate ? this.$dayjs(this.input[ 'time_from' ]).toDate() : today_till;
        Vue.set(this.input, 'time_till', time_till_input);
    }

    public get timeFrom(): string {
        return this.input.time_from?.toTimeString().substr(0, 5);
    }

    public get timeTill(): string {
        return this.input.time_till?.toTimeString().substr(0, 5);
    }

    public onBlur(event: FocusEvent, key: string): void {
        let input = new Date();
        if ( this.keepOriginalDate ) {
            input = this.$dayjs(this.input[ key ]).toDate();
        }

        const value = (event.target as HTMLInputElement).value.split(':').map(v => parseInt(v));
        input.setHours(value[ 0 ] || 0) && input.setMinutes(value[ 1 ] || 0) && input.setSeconds(0);

        Vue.set(this.input, key, input);
    }
}
